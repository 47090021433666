import React, { useEffect } from 'react'
import { List, Segment, Grid, Icon } from 'semantic-ui-react'
import { present } from '../../shared/utils'
import moment from 'moment';
import axios from 'axios';

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

const DATE_FORMAT_SHORT = "DD.MM.Y H:mm";

const PhoneCalls = (props) => {
  const { phoneCalls, setState } = props

  if (phoneCalls.length === 0) { return null }

  const pollForRecordings = (call) => {
    let phoneCall = phoneCalls.find(c => c.id === call.id)

    axios.get(`/phone_calls/recordings/${call.id}/`).then(({ data }) => {
      if (data.length > 0) {
        phoneCall['recordingUrls'] = data;
        setState({ phoneCalls: phoneCalls.map(c => c.id === call.id ? phoneCall : c) })
      } else {
        setTimeout(() => pollForRecordings(call), 3000)
      }
    }).catch(error => {
      console.error('error while polling for recordings', error)
    })
  }

  useEffect(() => {
    phoneCalls.forEach(call => {
      if (moment(call.createdAt).isAfter(moment().subtract(1, 'hour')) && (!call.recordingUrls || call.recordingUrls.length === 0)) {
        pollForRecordings(call);
      }
    });
  }, [phoneCalls]);

  return(
    <>
      <h2>{props.title || 'Phone Calls'}</h2>

      <List divided relaxed>
        {phoneCalls.map((call, i) => (
          <Segment key={i}>
            <Grid divided verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={4}>
                  <List.Item>
                    <List.Header>
                      <b>User</b>
                      {call.scheduledPhoneCallId && (
                        <i title="Scheduled phone call part of cold calling campaign">
                          <Icon
                            name='calendar alternate outline'
                            style={{ float: 'right' }}
                          />
                        </i>
                      )}
                    </List.Header>
                    <p>{call.user.name}</p>
                  </List.Item>
                </Grid.Column>
                <Grid.Column width={3}>
                  <List.Item>
                    <List.Header><b>Phone Number</b></List.Header>
                    {call.calledPhone}
                  </List.Item>
                </Grid.Column>
                <Grid.Column width={2}>
                  <List.Item>
                    <List.Header><b>Duration</b></List.Header>
                    {formatTime(call.duration)}
                  </List.Item>
                </Grid.Column>
                <Grid.Column width={2}>
                  <List.Item>
                    <List.Header><b>Status</b></List.Header>
                    {call.callStatus || 'undefined'}
                  </List.Item>
                </Grid.Column>
                <Grid.Column width={3}>
                  <List.Item>
                    <List.Header><b>Created At</b></List.Header>
                    {moment(call.createdAt).format(DATE_FORMAT_SHORT)}
                  </List.Item>
                </Grid.Column>
                <Grid.Column stretched>
                  {call.phoneCallOutcome && (
                    <List.Item>
                      <List.Header><b>Outcome</b></List.Header>
                      {call.phoneCallOutcome.name}
                    </List.Item>
                  )}
                </Grid.Column>
              </Grid.Row>
              {present(call.notes) && (
                <Grid.Row>
                  <Grid.Column width={16} verticalAlign='middle'>
                    <b style={{ marginRight: 5 }}>Notes:</b>
                    {call.notes}
                  </Grid.Column>
                </Grid.Row>
              )}
              {present(call.recordingUrls) && (
                call.recordingUrls.map((url, i) => {
                  let callDate = moment(call.createdAt)

                  let recordingFilename = `${call.calledPhone.replace(/ /g, '')}_${call.user.name.replace(/ /g, '-')}_${callDate.format('DD-MM_H-mm')}.wav`

                  return (
                    <Grid.Row key={i}>
                      <Grid.Column width={16} verticalAlign='middle'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <audio controls preload='metadata'>
                            <source src={`/phone_calls/recording?id=${url}`} type="audio/wav" />
                            Your browser does not support the audio tag.
                          </audio>
                          <a href={`/phone_calls/recording?id=${url}`}
                            download={recordingFilename}
                            style={{ color: 'black', marginLeft: '10px' }}
                          >
                            <Icon name="download" size="large" />
                          </a>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  )
                })
              )}
            </Grid>
          </Segment>
        ))}
      </List>
    </>
  )
}

export default PhoneCalls
