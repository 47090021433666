import React from 'react'
import { Header, Grid, Segment } from 'semantic-ui-react'
import { present, customPromptVariablesSelector } from "../../../shared/utils.js";
import CopyToClipboardDropdown from '../../../components/CopyToClipboardDropdown.js'
import nl2br from "react-nl2br";
import moment from 'moment';
import { createNotesFromPreviousCalls } from '../callInterfaceUtils.js';

const ScheduledPhoneCallInformation = ({ scheduledPhoneCall, scheduledPhoneCallId, outcomes }) => {
  if (!present(scheduledPhoneCall.phoneCallCampaignGroupAction)) return null;

  let previousScheduledPhoneCalls
  let futureScheduledPhoneCalls
  if (scheduledPhoneCall.scheduledPhoneCalls) {
    previousScheduledPhoneCalls = scheduledPhoneCall.scheduledPhoneCalls
      .filter(s => Number(s.id) !== Number(scheduledPhoneCallId))
      .filter(s => {
        if (scheduledPhoneCall.completedAt && !s.completedAt) return false
        return moment(s.completedAt || s.scheduledFor || s.createdAt).isBefore(scheduledPhoneCall.completedAt || scheduledPhoneCall.scheduledFor || scheduledPhoneCall.createdAt)
      });
    futureScheduledPhoneCalls = scheduledPhoneCall.scheduledPhoneCalls
      .filter(s => Number(s.id) !== Number(scheduledPhoneCallId))
      .filter(s => {
        if (s.completedAt) return false
        return moment(s.scheduledFor).isAfter(moment())
      });
  } else {
    previousScheduledPhoneCalls = []
    futureScheduledPhoneCalls = []
  }

  let notesFromPreviousCallsHtml = createNotesFromPreviousCalls(scheduledPhoneCall, previousScheduledPhoneCalls, outcomes)
  let daysSinceScheduled = moment(scheduledPhoneCall.scheduledFor).diff(moment({ hours: 0 }), 'days')

  return (
    <Segment>
      <Grid>

        <Grid.Row>
          <Grid.Column width={10}>
            <Header as='h3'>
              Scheduled Phone Call
              <Header.Subheader>
                #{previousScheduledPhoneCalls.length + 1} for this prospect
                {daysSinceScheduled > 0 && (<i> scheduled for {daysSinceScheduled} day{daysSinceScheduled > 1 && 's'} from now</i>)}
              </Header.Subheader>
            </Header>
          </Grid.Column>

          <Grid.Column width={6} floated='right'>
            <div style={{ textAlign: "right" }}>
              {present(scheduledPhoneCall.customPromptVariables) && present(customPromptVariablesSelector(scheduledPhoneCall.customPromptVariables)) &&
                <CopyToClipboardDropdown
                  text='Links & Variables'
                  search
                  selectOnNavigation={false}
                  selectOnBlur={false}
                  options={customPromptVariablesSelector(scheduledPhoneCall.customPromptVariables)}
                  value={null}
                  openLinks={true}
                />
              }
            </div>
          </Grid.Column>
        </Grid.Row>

      </Grid>

      <b>Campaign</b>
      <p>{scheduledPhoneCall.phoneCallCampaignGroupAction.campaignGroup.name} - {scheduledPhoneCall.messageVariant.name}</p>
      <b>Script</b>
      <p>{nl2br(scheduledPhoneCall.messageVariant.callDescription)}</p>
      {notesFromPreviousCallsHtml.length > 0 && (
        <React.Fragment>
          {<b>Notes from previous calls:</b>}
          {notesFromPreviousCallsHtml}
        </React.Fragment>
      )}
    </Segment>
  )
}

export default ScheduledPhoneCallInformation
