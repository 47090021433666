import React from 'react';
import { Message } from 'semantic-ui-react';
import { withRouter } from "react-router-dom";
import GlobalFlashMessage from "../../components/FlashMessage/index.js";

const FlashMessage = ({ location, history }) => {

  let message = location.state?.flash

  if (!message) return null;

  return(
    <GlobalFlashMessage
      error
      header='Error!'
      timeout={5000}
      removeOnTimeout
      content={message}
      onClose={() => history.replace()}
    />
  );
}

export default withRouter(FlashMessage);
