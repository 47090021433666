import React from "react";
import ProspectList from "./ProspectList.js";
import CampaignStatusFilter from "./CampaignStatusFilter.js";
import CrmStatusFilter from "./CrmStatusFilter.js";
import CampaignFilter from "./CampaignFilter.js";
import MotionDatePicker from "./MotionDatePicker.js";
import { Form, Segment, Header, Loader, Grid, Message, Icon, Button, Checkbox, Divider, Dropdown } from 'semantic-ui-react'
import { BrowserRouter as Router, Link, withRouter, } from "react-router-dom";
import axios from "axios";
import { debounce } from "debounce";
import InfiniteScroll from 'react-infinite-scroller';
import { present, } from "./utils.js";
import ProspectsExportModal from "./ProspectsExportModal.js";
import ProspectsEnrichModal from "./ProspectsEnrichModal.js";
import ProspectsEnrichDisplayModal from "./ProspectsEnrichDisplayModal.js";
import ProspectFormSimpleOpenClose from "./ProspectFormSimpleOpenClose.js";
import qs from "qs";
import moment from "moment";
import _ from "underscore";
import AdvancedFilters from "./FilteredProspects/AdvancedFilters.js";

function psQ(q) {
  return qs.parse(q.substring(1), { arrayFormat: 'brackets' })
}


export default class FilteredProspects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      didLoadOnce: false,
      nextPage: 1,
      hasMore: true,
    }
    this.didScrollToBottom = this.didScrollToBottom.bind(this);
    this.loadProspects = this.loadProspects.bind(this);
    this.onNewProspectSaved = this.onNewProspectSaved.bind(this);
    this.debouncedLoadProspects = debounce(this.loadProspects, 200);
  }

  componentDidMount() {
    try {
      let savedSearch = localStorage.getItem(`lastSetProspectsSearch-${this.props.currentUserId}-${this.props.currentAdminId}`)

      if (savedSearch !== null && savedSearch === "") { return this.loadProspects(); }
      if (savedSearch !== null && this.props.location.search === "") {
        this.props.history.push(this.props.location.pathname+savedSearch)
        return;
      }
    } catch(e) { console.log(e) }
    if (!this.props.location.search) { return this.loadProspects() }
    if (this.props.prospects.length === 0) { return this.loadProspects() }
  }

  fullPath(location) {
    return location.pathname+location.search;
  }

  removeTrailingSlash(url) {
    if (url.slice(-1) == "/") return url.slice(0, -1);
    return url
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.debouncedLoadProspects(1);
    }
  }

  didScrollToBottom() {
    if (!this.state.loading) {
      this.loadProspects(this.state.nextPage);
    }
  }

  searchWithPage(page=1, limit=null) {
    let { pathname, search } = this.props.location
    let queryObject = { ...psQ(search), page }
    if (present(limit)) {
      queryObject.limit = limit;
    }
    return "?"+qs.stringify(queryObject, { arrayFormat: 'brackets' });
  }

  loadProspects(page=1) {
    let { search } = this.props.location

    let path = `/prospects.json${this.searchWithPage(page)}`;
    this.setState({ loading: true, totalProspectsLoading: page === 1 ? true : this.state.totalProspectsLoading, })
    axios.get(path)
      .then(({ data }) => {
        if (search !== this.props.location.search) {
          console.log("discarding request ", search, "page:", page);
          return // this request is for a search that is no longer active, we discard its results
        }
        this.setState({ didLoadOnce: true, loading: false, nextPage: page+1, hasMore: data.prospects.length > 0 });
        if (page === 1) {
          this.fetchTotalProspects()
        }
        // this is necessary as we no longer guarantee dupliacte free prospects from backend for performance reasons
        let prospects = _.uniq(page === 1 ? data.prospects : [ ...this.props.prospects, ...data.prospects], false, prospect => prospect.id)
        this.props.onProspectsChange(prospects, this.props.totalProspects);
        try {
          localStorage.setItem(`lastSetProspectsSearch-${this.props.currentUserId}-${this.props.currentAdminId}`, search);
        } catch(e) { console.log(e) }
      })
      .catch(error => {
        console.log("error when loading details ", error);
        this.setState({loading: false})
      });
  }

  onNewProspectSaved(prospect) {
    this.props.history.push(`/prospects/${prospect.id}${this.props.location.search}`)
    if (this.props.prospects.filter(p => p.id === prospect.id).length === 0) {
      this.props.onProspectsChange([ prospect, ...this.props.prospects], this.props.totalProspects+1);
    }
  }

  fetchTotalProspects() {
    let { search, } = this.props.location
    let path = `/prospects_size${this.searchWithPage(1, this.prospectsSizeLimit()+1)}`;
    this.setState({ totalProspectsLoading: true })
    axios.get(path)
      .then(({ data }) => {
        if (search !== this.props.location.search) {
          console.log("discarding request ", search, "page:", page);
          return // this request is for a search that is no longer active, we discard its results
        }
        this.setState({ totalProspectsLoading: false });
        this.props.onProspectsChange(this.props.prospects, data);
      })
      .catch(error => {
        console.log("error when loading total prospects ", error);
        this.setState({totalProspectsLoading: false})
      });

  }

  prospectsSizeLimit() {
    if (this.props.companyId !== 1) {
      return 1000000
    }
    return 10000;
  }

  render() {
    let { prospects, users, currentUserId, prospectsEnrichments } = this.props;
    let { companyId, } = this.props;
    let totalProspectsString = this.props.totalProspects > this.prospectsSizeLimit() ? `more than ${this.prospectsSizeLimit()}` : this.props.totalProspects
    return (
      <div style={{marginTop: 30}}>
        <Grid>

          <Grid.Row>
            <Grid.Column>
              <Header as='h2' floated='left'>
                Prospects ({ this.state.totalProspectsLoading ? <Loader active inline size='tiny' /> : totalProspectsString })
              </Header>

            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Filters
          loading={this.state.loading}
          prospects={prospects}
          linkedInAccounts={this.props.linkedInAccounts}
          crmStatuses={this.props.crmStatuses}
          campaigns={this.props.campaigns}
          users={this.props.users}
          tags={this.props.tags}
          prospectsEnrichments={prospectsEnrichments}
          onNewProspectSaved={this.onNewProspectSaved}
          currentUserCanSeeProspectDetails={this.props.currentUserCanSeeProspectDetails}
          currentUserCanTriggerDataProviderEnrichment={this.props.currentUserCanTriggerDataProviderEnrichment}
          currentUserCanSeeDataProviderData={this.props.currentUserCanSeeDataProviderData}
          dataProviders={this.props.dataProviders}
          companyId={companyId}
        />

        { this.props.prospects.length > 0 && <div style={{paddingBottom: 10}}>
          <InfiniteScroll
            initialLoad={false}
            loadMore={this.didScrollToBottom}
            hasMore={this.state.hasMore}
          >
            <ProspectList
              prospects={prospects}
              didLoadOnce={this.state.didLoadOnce}
              crmStatuses={this.props.crmStatuses}
              onProspectChange={this.props.onProspectChange}
              users={users}
              campaigns={this.props.campaigns}
              currentUserId={currentUserId}
            />
            { this.state.loading && <Loader active inline='centered' /> }
          </InfiniteScroll>
        </div>
        }

        { this.props.prospects.length === 0 && this.state.didLoadOnce && <Grid.Column>
          <Message icon>
            <Icon name='filter' />
            <Message.Content>
              <Message.Header>No prospects match your filter</Message.Header>
              <p>You may wish to <Link to={this.props.location.pathname}>reset your filters</Link>.</p>
            </Message.Content>
          </Message>
        </Grid.Column> }

      </div>
    );
  }
}

FilteredProspects = withRouter(FilteredProspects)

class Filters extends React.Component {
  constructor(props) {
    super(props);

    if (props.location.search) {
      let currentQuery = psQ(this.props.location.search)

      // Remove tags from URL if they are not present in the tags list
      if (present(currentQuery.tags)) {
        let tags = currentQuery.tags.split(',').map(t => parseInt(t))
        currentQuery.tags = tags.filter(qt => props.tags.find(pt => pt.id === qt)).join(',')
        let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
        this.props.history.push(this.props.location.pathname + "?" + newQueryString)
      }
    }


    this.state = {
      prospectsEnrich: null,
    }
  }

  handleSearchChange(_, { value }) {
    let currentQuery = psQ(this.props.location.search)
    currentQuery.search = value;
    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleCampaignStatiFilterChange(_, { value }) {
    let currentQuery = psQ(this.props.location.search)
    currentQuery.campaignStati = value;
    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleCrmStatusesFilterChange(_, { value }) {
    let currentQuery = psQ(this.props.location.search)
    currentQuery.crmStatuses = value;
    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleWithEmailAddressFilterChange({ checked }) {
    let currentQuery = psQ(this.props.location.search)

    if (checked) {
      delete currentQuery.withoutEmailAddress
      currentQuery.withEmailAddress = checked
    } else {
      delete currentQuery.withEmailAddress
    }

    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname + "?" + newQueryString)
  }

  handleWithoutEmailAddressFilterChange({ checked }) {
    let currentQuery = psQ(this.props.location.search)

    if (checked) {
      delete currentQuery.withEmailAddress
      currentQuery.withoutEmailAddress = checked
    } else {
      delete currentQuery.withoutEmailAddress
    }

    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname + "?" + newQueryString)
  }

  handleWithPhoneNumberFilterChange({ checked }) {
    let currentQuery = psQ(this.props.location.search)

    if (checked) {
      delete currentQuery.withoutPhoneNumber
      currentQuery.withPhoneNumber = checked
    } else {
      delete currentQuery.withPhoneNumber
    }

    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname + "?" + newQueryString)
  }

  handleWithoutPhoneNumberFilterChange({ checked }) {
    let currentQuery = psQ(this.props.location.search)

    if (checked) {
      delete currentQuery.withPhoneNumber
      currentQuery.withoutPhoneNumber = checked
    } else {
      delete currentQuery.withoutPhoneNumber
    }

    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname + "?" + newQueryString)
  }

  handleEnrichedFilterChange({ checked }) {
    let currentQuery = psQ(this.props.location.search)
    currentQuery.enriched = checked || null;
    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname + "?" + newQueryString)
  }

  handleCampaignFilterChange(_, { value }) {
    let currentQuery = psQ(this.props.location.search)
    currentQuery.campaigns = value;
    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handlePreviousEnrichmentsFilterChange(value) {
    let currentQuery = psQ(this.props.location.search)
    currentQuery.previous_enrichments = value.filter(v => present(v)).join(',');
    if (currentQuery.previous_enrichments.length == 0) {
      delete currentQuery.previous_enrichments
    }
    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname + "?" + newQueryString)
  }

  handleTagsFilterChange(value) {
    let currentQuery = psQ(this.props.location.search)
    currentQuery.tags = value.filter(v => present(v)).join(',');
    if (currentQuery.tags.length == 0) {
      delete currentQuery.tags
    }
    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname + "?" + newQueryString)
  }

  handleDateFilterChange({ range, from, to }) {
    let currentQuery = psQ(this.props.location.search)

    delete currentQuery.date
    delete currentQuery.date_from
    delete currentQuery.date_to

    if (range && range !== '') currentQuery.date = range;
    if (from && from !== '') currentQuery.date_from = moment(from).format("YYYY-MM-DD");
    if (to && to !== '') currentQuery.date_to = moment(to).format("YYYY-MM-DD");

    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleRemainedInCurrentCrmStatusDateFilterChange({ range, from, to }) {
    let currentQuery = psQ(this.props.location.search)

    delete currentQuery.remained_in_status_date
    delete currentQuery.remained_in_status_date_from

    if (range && range !== '') currentQuery.remained_in_status_date = range;
    if (from && from !== '') currentQuery.remained_in_status_date_from = moment(from).format("YYYY-MM-DD");

    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname + "?" + newQueryString)
  }

  handleResetCrmStatusHistoryFilter(e) {
    e.preventDefault();
    let currentQuery = psQ(this.props.location.search)
    delete currentQuery["crmStatusHistory"]
    let newQueryString = qs.stringify(currentQuery, { arrayFormat: 'brackets' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  getPeriodStartDates = () => {
    const format = 'DD/MM/YYYY';

    return {
      thisWeek: moment().startOf('isoWeek').format(format),
      lastWeek: moment().subtract(1, 'weeks').startOf('isoWeek').format(format),
      thisMonth: moment().startOf('month').format(format),
      lastMonth: moment().subtract(1, 'months').startOf('month').format(format),
      thisQuarter: moment().startOf('quarter').format(format),
      lastQuarter: moment().subtract(1, 'quarters').startOf('quarter').format(format),
      thisYear: moment().startOf('year').format(format),
      lastYear: moment().subtract(1, 'years').startOf('year').format(format),
    };
  };

  render () {
    let { prospects, onNewProspectSaved } = this.props;
    let query = psQ(this.props.location.search)
    let {
      search, campaignStati, crmStatuses, campaigns, crmStatusHistory, linkedInAccountOwners, date_from, date_to, date,
      users, remained_in_status_date, remained_in_status_date_from, previous_enrichments, tags
    } = query

    tags = tags ? tags.split(',').map(t => parseInt(t)) : []

    previous_enrichments ||= ""

    const dates = this.getPeriodStartDates();

    return (
      <Segment>
        <Grid>
        <Grid.Column width={10}>
            <h3>
              Filter&nbsp; &nbsp;
              { this.props.loading &&
                  <Loader active inline size='tiny' className={ prospects.length === 0 ? "" : "delayVisibility" } />
              }
            </h3>
          </Grid.Column>
          <Grid.Column width={6} style={{textAlign: "right"}}>
            <ProspectFormSimpleOpenClose
              trigger={<Button size="mini" labelPosition='left' icon><Icon name="add"></Icon>Create Prospect</Button>}
              onSave={onNewProspectSaved}
              campaigns={this.props.campaigns}
              defaultProspect={{}}
            />&nbsp;&nbsp;
            <ProspectsExportModal filterParams={query} />&nbsp;&nbsp;
            {this.props.currentUserCanTriggerDataProviderEnrichment && !present(this.state.prospectsEnrich) && (
              <ProspectsEnrichModal
                filterParams={query}
                crmStatuses={this.props.crmStatuses}
                dataProviders={this.props.dataProviders}
                setProspectsEnrich={prospectsEnrich => this.setState({ prospectsEnrich })}
              />
            )}
            {this.props.currentUserCanTriggerDataProviderEnrichment && present(this.state.prospectsEnrich) && (
              <ProspectsEnrichDisplayModal
                crmStatuses={this.props.crmStatuses}
                dataProviders={this.props.dataProviders}
                prospectsEnrich={this.state.prospectsEnrich}
                setProspectsEnrich={prospectsEnrich => this.setState({ prospectsEnrich })}
                open={true}
                onClose={() => this.setState({ prospectsEnrich: null })}
              />
            )}
          </Grid.Column>
        </Grid>
        <Form>
          <Grid>
            <Grid.Column width={6}>
              <Form.Input value={search || ""} autoFocus fluid label='Search...' placeholder='Search...' onChange={this.handleSearchChange.bind(this)}/>
            </Grid.Column>

            <Grid.Column width={5}><CampaignStatusFilter
                filteredCampaignStati={campaignStati}
                onChange={this.handleCampaignStatiFilterChange.bind(this)}
              /></Grid.Column>
            <Grid.Column width={5}>
              <Form.Field>
                <label>Tags</label>
                <Dropdown
                  fluid
                  search
                  selection
                  multiple
                  options={
                    this.props.tags.map(t => { return { key: t.id, value: t.id, text: t.name } }).sort(
                      (a, b) => a.text.localeCompare(b.text)
                    )
                  }
                  value={tags || []}
                  onChange={(_e, element) => this.handleTagsFilterChange(element.value)}
                  id="tags-dropdown"
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={5}>
              <CrmStatusFilter
                filteredCrmStatuses={crmStatuses}
                crmStatuses={this.props.crmStatuses}
                onChange={this.handleCrmStatusesFilterChange.bind(this)}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <CampaignFilter
                filteredCampaigns={campaigns}
                campaigns={this.props.campaigns}
                onChange={this.handleCampaignFilterChange.bind(this)}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <MotionDatePicker
                label="Filter by request sent at"
                range={date || 'all' /* if we do not add this  || 'all' the DatePicker will call onChange with all immediatley leading to our saved-search logic being overwritten i think because of a race condition with history.push() */}
                from={date_from}
                to={date_to}
                onChange={this.handleDateFilterChange.bind(this)}
                rangeDatepicker={true}
              />
            </Grid.Column>
            <AdvancedFilters
              query={query}
              dates={dates}
              prospectsEnrichments={this.props.prospectsEnrichments}
              currentUserCanSeeProspectDetails={this.props.currentUserCanSeeProspectDetails}
              currentUserCanSeeDataProviderData={this.props.currentUserCanSeeDataProviderData}
              handleWithPhoneNumberFilterChange={this.handleWithPhoneNumberFilterChange.bind(this)}
              handleWithoutPhoneNumberFilterChange={this.handleWithoutPhoneNumberFilterChange.bind(this)}
              handleWithEmailAddressFilterChange={this.handleWithEmailAddressFilterChange.bind(this)}
              handleWithoutEmailAddressFilterChange={this.handleWithoutEmailAddressFilterChange.bind(this)}
              handlePreviousEnrichmentsFilterChange={this.handlePreviousEnrichmentsFilterChange.bind(this)}
              handleRemainedInCurrentCrmStatusDateFilterChange={this.handleRemainedInCurrentCrmStatusDateFilterChange.bind(this)}
              crmStatusHistory={crmStatusHistory}
              crmStatuses={this.props.crmStatuses}
              campaigns={this.props.campaigns}
              users={this.props.users}
              companyId={this.props.companyId}
            />
          </Grid>
        </Form>
      </Segment>
    )
  }
}


Filters = withRouter(Filters)
