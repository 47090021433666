import React from 'react';
import { Table } from 'semantic-ui-react';
import { withRouter } from "react-router-dom";
import IncomingCall from './IncomingCall';
import { present } from '../../shared/utils';

const IncomingCalls = (props) => {
  if (!props.device) { return null; }
  if (props.incomingCalls.length == 0) { return null; }

  let notCurrentCalls = props.incomingCalls.filter(call => {
    let locationNotIncludingScpId = !location.href.includes(`incoming/${call.customParameters.get('scheduledPhoneCallId')}`)
    let callDataNotIncludingScpId = call.customParameters?.get('scheduledPhoneCallId') != props.callData?.customParameters?.get('scheduledPhoneCallId')

    return locationNotIncludingScpId && callDataNotIncludingScpId
  })

  // When there is ongoing call we display it only if it's current call
  notCurrentCalls = notCurrentCalls.filter(call => {
    let callInProgress = present(props.callData) && !props.callData.ended

    if  (!callInProgress) { return true; }

    return props.callData?.call == call
  })

  if (notCurrentCalls.length == 0) { return null; }

  return (
    <div style={{ marginTop: 50 }}>
      <Table celled style={{ marignTop: 0 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Incoming Call</Table.HeaderCell>
            <Table.HeaderCell>From</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Recommended Prospect(s)</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            notCurrentCalls.map((call, i) => (
              <IncomingCall
                device={props.device}
                call={call}
                phoneCallOutcomes={props.phoneCallOutcomes}
                data={props.data}
                handlers={props.handlers}
                key={i}
                pageChangeBlocker={props.pageChangeBlocker}
              />
            ))
          }
        </Table.Body>
      </Table>
    </div>
  );
}

export default withRouter(IncomingCalls);
