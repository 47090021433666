import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { Button, Form, Grid, Segment, Message, Input } from 'semantic-ui-react';
import axios from 'axios';
import TwilioCall from '../../shared/Twilio/TwilioCall.js';
import { present } from "../../shared/utils.js";
import PhoneCalls from './PhoneCalls.js';
import ProspectView from './CallInterface/ProspectView.js';
import PhoneNumberControl from './PhoneNumberControl.js';
import Help from './Help.js';
import { fetchCall, handleSave, reloadScheduledPhoneCallPhoneCalls } from './callInterfaceUtils.js';
import qs from 'qs';
import ScheduledPhoneCallInformation from './CallInterface/ScheduledPhoneCallInformation.js';
import ScheduledPhoneCallControls from './CallInterface/ScheduledPhoneCallControls.js';
const MockTwilioCall = require('../../shared/Test/MockTwilioCall.js').default;

const CallInterface = (props) => {
  const { blockProspectChange, campaignGroupId, scheduledPhoneCallId, history, data, handlers, pageChangeBlocker, } = props;
  const { device, incomingCalls, callData } = data;
  const { setCallData } = handlers;
  const reloading = useRef(false)

  const callType = history.location.pathname.includes('incoming') ? 'incoming' : 'outgoing';

  const callInProgress = present(callData) && !callData.ended

  let clientWaiting = history?.location?.search?.includes('clientWaiting=true')
  let call = incomingCalls.find(call => call.customParameters.get('scheduledPhoneCallId') == scheduledPhoneCallId)

  const outcomes = props.phoneCallOutcomes;

  const [scheduledPhoneCall, setScheduledPhoneCall] = useState(props.scheduledPhoneCall || {})
  const [loading, setLoading] = useState(false);
  const [displayErrors, setDisplayErrors] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);

  useEffect(() => {
    if (!scheduledPhoneCallId && callType === 'outgoing') {
      fetchNextCall('replace');
    }

    return () => {
      if (pageChangeBlocker.current) {
        window.removeEventListener("beforeunload", pageChangeBlocker.current[0]);
        pageChangeBlocker.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (scheduledPhoneCallId && scheduledPhoneCallId != scheduledPhoneCall.id) {
      let path
      if (callType === 'outgoing') {
        path = `/cold_calling/${campaignGroupId}/${scheduledPhoneCallId}.json`
      } else if (callType === 'incoming') {
        path = `/cold_calling/incoming/${scheduledPhoneCallId}.json?clientWaiting=${clientWaiting}`
      }
      fetchCall(
        path,
        props,
        setScheduledPhoneCall,
        setPhonesAndSelectedDataProviderInformation,
        setSaveDisabled
      );
    }

    // When directly visited the page with a scheduled phone call id we still have to respect the locking mechanism
    if (scheduledPhoneCallId && scheduledPhoneCallId == scheduledPhoneCall?.id && !scheduledPhoneCall.receivedLock) {
      props.history.replace(`/cold_calling${callType == 'incoming' && '/incoming'}`, { flash: "You cannot access this scheduled phone call because someone else is currently working on it." })
    }
  }, [scheduledPhoneCallId])

  useEffect(() => {
    if (callInProgress && !pageChangeBlocker.current) {
      const onBeforeUnload = (ev) => {
        if (ev) {
          ev.preventDefault();
          ev.returnValue = 'Are you sure you want to leave?';
        }

        return 'Are you sure you want to leave?';
      }

      window.addEventListener("beforeunload", onBeforeUnload);

      pageChangeBlocker.current = [onBeforeUnload, callData.phoneCallId || callData.call.customParameters.get('phoneCallId')];
    }

    if (clientWaiting && !callInProgress && present(callData)) {
      props.history.replace(`/cold_calling/incoming/${scheduledPhoneCallId}`)
    }
  }, [callInProgress]);

  useEffect(() => {
    if (!present(scheduledPhoneCall) || callData.ended !== true || loading) return;

    const phoneCall = scheduledPhoneCall.phoneCalls.find(call => call.id == callData.phoneCallId)

    if (!phoneCall) return;

    let path
    if (callType === 'outgoing') {
      path = `/cold_calling/${campaignGroupId}/${scheduledPhoneCallId}/phone_calls/${phoneCall.id}.json`
    } else if (callType === 'incoming') {
      path = `/cold_calling/incoming/${scheduledPhoneCallId}/phone_calls/${phoneCall.id}.json`
    }

    axios.get(path).then(response => {
      let freshPhoneCall = response.data
      let duration = phoneCall.duration || freshPhoneCall.duration

      setScheduledPhoneCall((nextScheduledPhoneCall) => {
        let phoneCallToChange = nextScheduledPhoneCall.phoneCalls.find(call => call.id == callData.phoneCallId)
        // Busy calls are instantly rejected, in all other cases we are able to guess
        // if the call was connected or not based on the duration of the call
        if (freshPhoneCall.incoming) {
          phoneCallToChange.connected = 'yes'
        } else if (freshPhoneCall.callStatus == 'busy') {
          phoneCallToChange.connected = 'busy'
        } else if (duration <= 5) {
          phoneCallToChange.connected = 'invalid-number'
        } else if (duration > 5 && duration <= 30) {
          phoneCallToChange.connected = 'nobody-picked-up'
        } else {
          phoneCallToChange.connected = 'yes'
        }

        return { ...nextScheduledPhoneCall }
      })
    })
  }, [callData, loading]);

  if (process.env.NODE_ENV === 'test') {
    // To test the incoming calls popup we're adding this snippet, based on the URL parameters
    // the UI would trigger the incoming call event, this is only for testing
    useEffect(() => {
      if (device) {
        const parsedGetParams = qs.parse(props.location.search.replace("?", ""));
        if (parsedGetParams.mockIncoming == 'true') {
          let mockedTwilioCall = new MockTwilioCall();

          mockedTwilioCall.customParameters.set('scheduledPhoneCallId', parsedGetParams.spc);
          mockedTwilioCall.customParameters.set('prospectIds', parsedGetParams.pids);
          mockedTwilioCall.customParameters.set('phoneCallId', parsedGetParams.pc);
          mockedTwilioCall.parameters.From = '+1234567890';

          device.emit('incoming', mockedTwilioCall);
        }
      }
    }, [device])
  }

  if (clientWaiting && !call && !present(callData)) {
    let flash = 'Someone else already started working on this call or the client ended the call.'
    if (localStorage.getItem('coldCallingLastPage')) {
      history.push(localStorage.getItem('coldCallingLastPage'), { flash })
      localStorage.removeItem('coldCallingLastPage')
    } else {
      history.push(`/cold_calling`, { flash })
    }
  }

  const setPhonesAndSelectedDataProviderInformation = (scheduledPhoneCall) => {
    let tempPhones = scheduledPhoneCall.prospect?.dataProviderInformation?.filter(info => info.type === 'phone') || []
    let callingPhone = scheduledPhoneCall.sourcePhoneCall?.callingPhone
    let preselectedPhone
    if (callType === 'outgoing') {
      preselectedPhone = tempPhones[0]
    } else if (callType === 'incoming') {
      preselectedPhone = tempPhones.find(phone => phone.value == callingPhone || phone.userSanitizedValue == callingPhone)
    }
    setPhones(tempPhones)
    setSelectedDataProviderInformation(preselectedPhone || {})
  }

  let fetchNextCall
  if (callType === 'outgoing') {
    fetchNextCall = async (navigationMethod = 'push') => {
      try {
        const response = await axios.get(`/cold_calling/${campaignGroupId}/next`);
        if (response.status === 204) {
          props.history.push(`/cold_calling`, { flash: 'No more calls to process.' })
        } else {
          if (navigationMethod === 'push') {
            props.history.push(`/cold_calling/${campaignGroupId}/${response.data.id}`)
          } else {
            // We want to use replace only when the user been redirected to the next call page without
            // scheduledPhoneCallId in the URL. Ohterwise with `push` the user cannot go back to the original page.
            props.history.replace(`/cold_calling/${campaignGroupId}/${response.data.id}`)
          }
        }
      } catch (error) {
        if (error.response.status == 307 && error.response.data.status == 'prospect_distribution_running') {
          props.history.replace(`/cold_calling`, { flash: PROSPECT_DISTRIBUTION_FLASH })
        } else {
          console.error('Failed to fetch next call:', error);
        }
      }
    };
  } else if (callType === 'incoming') {
    fetchNextCall = async () => {
      if (localStorage.getItem('coldCallingLastPage')) {
        history.push(localStorage.getItem('coldCallingLastPage'))
        localStorage.removeItem('coldCallingLastPage')
      } else {
        history.push(`/cold_calling/incoming`)
      }
    };
  }

  const changeCurrentPhoneCallDuration = (duration, phoneCallId) => {
    let phoneCall = scheduledPhoneCall.phoneCalls.find(call => call.id == phoneCallId)
    if (phoneCall) {
      setScheduledPhoneCall((prevScheduledPhoneCall) => {
        phoneCall = prevScheduledPhoneCall.phoneCalls.find(call => call.id == phoneCallId)
        phoneCall.duration = duration
        return ({ ...prevScheduledPhoneCall })
      })
    }
  }

  const setScheduledPhoneCallPhoneCalls = (phoneCalls) => {
    setScheduledPhoneCall((prevScheduledPhoneCall) => ({ ...prevScheduledPhoneCall, phoneCalls }));
  }

  const [phones, setPhones] = useState(scheduledPhoneCall.prospect?.dataProviderInformation?.filter(info => info.type === 'phone') || [])
  const [selectedDataProviderInformation, setSelectedDataProviderInformation] = useState(phones[0] || {});

  if (!scheduledPhoneCallId) return null;
  if (!present(scheduledPhoneCall)) return null;

  const handleReloadScheduledPhoneCallPhoneCalls = () => {
    let path
    if (callType === 'outgoing') {
      path = `/cold_calling/${campaignGroupId}/${scheduledPhoneCallId}.json`
    } else if (callType === 'incoming') {
      path = `/cold_calling/incoming/${scheduledPhoneCallId}.json`
    }
    reloadScheduledPhoneCallPhoneCalls(
      path,
      reloading,
      scheduledPhoneCall,
      setScheduledPhoneCall,
      setLoading,
    )
  }

  return (
    <>
      <Help />
      <Grid divided style={{ marginTop: 15 }}>
        <Grid.Column width={8} style={{ marginTop: 10 }}>
          <ProspectView
            scheduledPhoneCall={scheduledPhoneCall}
            setScheduledPhoneCall={setScheduledPhoneCall}
            blockProspectChange={blockProspectChange}
            crmStatuses={props.crmStatuses}
            users={props.users}
            currentUserId={props.currentUserId}
            campaigns={props.campaigns}
            tags={props.tags}
            outcomes={outcomes}
            setPhonesAndSelectedDataProviderInformation={setPhonesAndSelectedDataProviderInformation}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form error={displayErrors}>
            <TwilioCall
              prospectId={scheduledPhoneCall?.prospect?.id}
              scheduledPhoneCallId={scheduledPhoneCall.id}
              sessionScope='incoming'
              callData={callData}
              setCallData={setCallData}
              reloadScheduledPhoneCallPhoneCalls={handleReloadScheduledPhoneCallPhoneCalls}
              changeCurrentPhoneCallDuration={changeCurrentPhoneCallDuration}
              selectedDataProviderInformation={selectedDataProviderInformation}
              device={device}
              forCall={call}
            >
              {scheduledPhoneCall.prospect ? (
                <PhoneNumberControl
                  phones={phones}
                  setPhones={setPhones}
                  scheduledPhoneCallId={scheduledPhoneCall.id}
                  prospectId={scheduledPhoneCall?.prospect?.id}
                  selectedDataProviderInformation={selectedDataProviderInformation}
                  setSelectedDataProviderInformation={setSelectedDataProviderInformation}
                  callInProgress={callInProgress}
                />
              ) : (
                <Input
                  value={scheduledPhoneCall.phoneCalls.slice(-1)[0].callingPhone}
                  disabled
                  icon='dropdown'
                />
              )}
            </TwilioCall>

            <ScheduledPhoneCallInformation
              scheduledPhoneCallId={scheduledPhoneCallId}
              scheduledPhoneCall={scheduledPhoneCall}
              outcomes={outcomes}
            />

            {scheduledPhoneCall.phoneCalls.length > 0 && (
              <Segment>
                <h3>Calls</h3>
                <PhoneCalls
                  currentScheduledPhoneCall={scheduledPhoneCall}
                  phoneCalls={scheduledPhoneCall.phoneCalls}
                  setScheduledPhoneCallPhoneCalls={setScheduledPhoneCallPhoneCalls}
                  currentCall={callData}
                  callInProgress={callInProgress}
                  displayErrors={displayErrors}
                  scheduledPhoneCalls={scheduledPhoneCall.scheduledPhoneCalls}
                  outcomes={outcomes}
                />
              </Segment>
            )}

            <ScheduledPhoneCallControls
              scheduledPhoneCallId={scheduledPhoneCallId}
              scheduledPhoneCall={scheduledPhoneCall}
              setScheduledPhoneCall={setScheduledPhoneCall}
              displayErrors={displayErrors}
              outcomes={outcomes}
            />

            <Message
              error
              header='Cannot save changes'
              content='Please fill out all required fields.'
            />
            <Form.Field
              control={Button}
              content={(callType == 'incoming' || callInProgress) ? 'Save' : 'Save & Goto Next'}
              onClick={
                () => handleSave(
                  callType,
                  campaignGroupId,
                  scheduledPhoneCall,
                  setDisplayErrors,
                  pageChangeBlocker,
                  fetchNextCall,
                  callInProgress,
                  setSaveDisabled,
                )}
              style={{ width: '100%' }}
              disabled={saveDisabled}
            />
          </Form>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default withRouter(CallInterface);
