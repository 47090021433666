import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { Grid, Dropdown, Icon, Button, Popup } from 'semantic-ui-react';
import _ from "underscore";
import AudioMeter from './AudioMeter';
import axios from "axios";
import { classnames } from "tailwindcss-classnames";
import { present } from '../utils';
import moment from 'moment';

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

const TwilioCall = (props) => {
  const { device, selectedDataProviderInformation, sessionScope, forCall, disablePickup, blockCallButton } = props;

  if (!device) { return null }

  const handleCallDataChange = (newCallData, overwrite = false) => {
    setCallData((prevCallData) => {
      let newData
      if (overwrite) {
        newData = newCallData
      } else {
        newData = { ...prevCallData, ...newCallData }
      }

      setLocalCallData(newData);
      return setCallData(newData);
    });
  }

  // This component should work with provided top level callData or either manage the callData on its own
  let callData, setCallData
  if (props.callData && props.setCallData) {
    callData = props.callData
    setCallData = props.setCallData
  } else {
    [callData, setCallData] = useState(props.callData || {});
  }
  const [localCallData, setLocalCallData] = useState(props.callData || {});
  const [microphones, setMicrophones] = useState([]);
  const [activeMicrophone, setActiveMicrophone] = useState(null);
  const [speakers, setSpeakers] = useState([]);
  const [activeSpeaker, setActiveSpeaker] = useState(null);
  const [muted, setMuted] = useState(false);
  const [callDuration, setCallDuration] = useState(props.callData?.startedAt ? moment().diff(props.callData?.startedAt, 'seconds') : 0);
  const [outputLevel, setOutputLevel] = useState(0);
  const [permissionsMissingError, setPermissionsMissingError] = useState(false);
  const intervalIdRef = useRef(null);

  let callInProgress = present(callData) && !callData.ended
  let currentCall = false
  if (callInProgress && callData.phoneCallId == localCallData.phoneCallId) {
    currentCall = true
  }

  useEffect(() => {
    if (callData.call && !callData.ended) {
      startCallDurationMeasurment(false)
    }

    () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }
  }, []);

  useEffect(() => {
    if (callInProgress && props.callData.call) {
      setLocalCallData(props.callData)
      setCallDuration(moment().diff(props.callData?.startedAt, 'seconds'))
      if (!callData.ended) {
        startCallDurationMeasurment(false)
      }
    }
  }, [props.scheduledPhoneCallId, callInProgress])

  useEffect(() => {
    if (callData.ended) {
      stopCallDurationMeasurment()
    }
  }, [callData.ended]);

  const setupCallbacksOnCall = (call, phoneCallId) => {
    call.on('accept', () => {
      handleCallDataChange({ call, phoneCallId });

      startCallDurationMeasurment();
      if (props.reloadScheduledPhoneCallPhoneCalls) {
        props.reloadScheduledPhoneCallPhoneCalls()
      }
    })

    call.on('disconnect', () => {
      handleCallDataChange({ call: null, ended: true, endedAt: moment() });
      if (props.onCallEnd) {
        props.onCallEnd();
      }
    });

    call.on('error', (error) => {
      handleCallDataChange({ call: null, ended: true, endedAt: moment() });
      if (props.reloadScheduledPhoneCallPhoneCalls) {
        props.reloadScheduledPhoneCallPhoneCalls()
      }
      if (props.onCallEnd) {
        props.onCallEnd();
      }
    });

    call.on('volume', (_inputVolume, outputVolume) => {
      setOutputLevel(outputVolume);
    })
    call.on('cancel', () => {
      console.log('DEBUG: The call has been canceled.');
    });
    call.on('reconnected', () => {
      console.log('DEBUG: The call has regained connectivity.')
    });
    call.on('reconnecting', (twilioError) => {
      console.log('DEBUG: Connectivity error: ', twilioError);
    });
    call.on('reject', () => {
      console.log('DEBUG: The call was rejected.');
    });
    call.on('warning', function (warningName, warningData) {
      console.log('DEBUG: Warning: ', warningName, warningData);
    });
  }

  const handleCallingByKeyboard = (event) => {
    if (blockCallButton) return;

    if (event.ctrlKey && event.shiftKey && event.code === 'KeyE') {
      if (!callData.call) {
        makeCall();
      } else {
        endCall();
      }
    }
  };

  useEffect(() => {
    if (props.changeCurrentPhoneCallDuration && callInProgress && currentCall) {
      props.changeCurrentPhoneCallDuration(callDuration, callData.call.customParameters.get('phoneCallId'))
    }
    if (callDuration > 0 && (callDuration % 60) == 0) {
      let eventData = {
        scheduled_phone_call_id: props.scheduledPhoneCallId,
        campaign_group_id: props?.match?.params?.campaignGroupId,
        prospect_id: props?.match?.params?.prospectId
      }
      window.dispatchEvent(new CustomEvent('ongoing_call', { detail: eventData }), )
    }
  }, [callDuration]);

  useEffect(() => {
    let intervalId
    checkMicrophonePermissions().then((result) => {
      if (!result) {
        intervalId = setInterval(() => {
          checkMicrophonePermissions().then((result) => {
            if (result) {
              clearInterval(intervalId)
            }
          })
        }, 3000);
      }
    })

    return () => clearInterval(intervalId);
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleCallingByKeyboard);

    return () => {
      window.removeEventListener('keydown', handleCallingByKeyboard);
    }
  }, [props])

  const makeCall = async () => {
    if (forCall) {
      phoneCallId = forCall.customParameters.get('phoneCallId')

      setupCallbacksOnCall(forCall, phoneCallId);

      forCall.accept()

      handleCallDataChange({ call: forCall, startedAt: moment() }, true);

      return
    }

    let createPhoneCallUrl

    if (sessionScope == 'naked_phone_call') {
      createPhoneCallUrl = `/phone_calls/create_naked_phone_call?phoneNumber=${selectedDataProviderInformation.value}`
    } else if (sessionScope == 'cold_calling') {
      createPhoneCallUrl = `/phone_calls/create_phone_call/${props.scheduledPhoneCallId}?data_provider_information_id=${selectedDataProviderInformation.id}`
    } else if (sessionScope == 'incoming') {
      createPhoneCallUrl = `/phone_calls/create_phone_call_for_incoming_phone_call/${props.scheduledPhoneCallId}?data_provider_information_id=${selectedDataProviderInformation?.id}`
    } else if (sessionScope == 'prospect') {
      createPhoneCallUrl = `/phone_calls/create_prospect_phone_call/${props.prospectId}?data_provider_information_id=${selectedDataProviderInformation.id}`
    }

    let response = await axios.get(createPhoneCallUrl).catch((error) => {
      let { data, status } = error.response

      if (data.error = 'Prospects being distributed' && status == 422) {
        props.history.replace('/cold_calling', { flash: 'Prospects are being distributed, please wait a few minutes and try again.' })
      }

      if (status == 401) {
        props.history.replace('/cold_calling', { flash: 'You are not authorized to peform this call.' })
      }
    })

    if (!response) return;

    let phoneCallId = response.data.id
    let calledPhone = response.data.calledPhone

    handleCallDataChange({ phoneCallId });

    if (props.reloadScheduledPhoneCallPhoneCalls) {
      props.reloadScheduledPhoneCallPhoneCalls()
    }

    if (present(calledPhone)) {
      const call = await device.connect({
        params: {
          To: calledPhone,
          phoneCallId,
        }
      });
      handleCallDataChange({ call, startedAt: moment() }, true);

      setupCallbacksOnCall(call, phoneCallId);
    }
  };

  const endCall = () => {
    if (callData.call) {
      callData.call.disconnect();
    }
  };

  const sendDigit = (digit) => {
    if (callData.call) {
      callData.call.sendDigits(digit);
    }
  };

  const updateAudioDevices = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    await stream.getTracks().forEach(track => track.stop());

    const availableMicrophones = Array.from(device.audio.availableInputDevices)
    const availableSpeakers = Array.from(device.audio.availableOutputDevices)

    setMicrophones(availableMicrophones);
    setSpeakers(availableSpeakers);

    setActiveMicrophone(device.audio.inputDevice?.deviceId || 'default')
    setActiveSpeaker(Array.from(device.audio.speakerDevices.get())[0]?.deviceId)
  };

  const handleActiveMicrophoneChange = (deviceId) => {
    device.audio.setInputDevice(deviceId);
    setActiveMicrophone(deviceId)
  };

  const handleActiveSpeakerChange = (deviceId) => {
    device.audio.speakerDevices.set(deviceId);
    setActiveSpeaker(deviceId)
  };

  const startCallDurationMeasurment = (resetCallDuration = true) => {
    if (!intervalIdRef.current) {
      resetCallDuration && setCallDuration(0);
      const id = setInterval(() => {
        setCallDuration(prevCounter => prevCounter + 1);
      }, 1000);
      intervalIdRef.current = id;
    }
  };

  const stopCallDurationMeasurment = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    setCallDuration(0);
  };

  useEffect(() => {
    updateAudioDevices();
    device.audio.on('deviceChange', () => updateAudioDevices());
  }, [device]);

  useEffect(() => {
    if (callData.call) {
      callData.call.mute(muted);
    }
  }, [muted]);

  const checkMicrophonePermissions = async () => {
    try {
      let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      await stream.getTracks().forEach(track => track.stop());
      setPermissionsMissingError(false);
      return true
    } catch (error) {
      setPermissionsMissingError(true);
      return false
    }
  }

  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={16}>
          <Button
            icon={(callInProgress && currentCall) ?
              <i className="icon fa-solid fa-phone-slash"></i> :
              <i className="icon fa-solid fa-phone"></i>
            }
            color={classnames(
              { "blue": forCall && !callInProgress },
              { "red": callInProgress && currentCall },
              { "green": (!callInProgress && !currentCall && !forCall) },
              { "grey": (callInProgress && !currentCall) }
            )}
            onClick={callData.call ? endCall : makeCall}
            disabled={!selectedDataProviderInformation || permissionsMissingError || (callInProgress && !currentCall) || disablePickup || blockCallButton}
            size="big"
            style={{ marginRight: 10 }}
          />

          {permissionsMissingError && (
            <Popup
              position="bottom center"
              trigger={<Icon name="exclamation circle" color="red" />}
              size="small"
              content="Microphone access is blocked. Please enable microphone permissions for motion-group.at in your browser settings." />
          )}

          {(callInProgress || callDuration > 0) && currentCall && (
            <span style={{ marginRight: 10 }}>{formatTime(callDuration)}</span>
          )}

          {props.children}

          <Popup
            trigger={<Button icon={<Icon name="headphones" />} style={{ marginLeft: 10, marginRight: 5 }} />}
            on="click"
            position="bottom center"
            className="audioDevicesPopup"
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <label>Microphone:</label>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Dropdown
                    fluid
                    search
                    selection
                    options={microphones.map(([deviceId, details]) => { return { key: deviceId, value: deviceId, text: details.label } })}
                    value={activeMicrophone}
                    onChange={(_e, element) => handleActiveMicrophoneChange(element.value)}
                    placeholder='Select Microphone'
                  />
                  <AudioMeter meteredDevice={activeMicrophone} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <label>Speaker:</label>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Dropdown
                    fluid
                    search
                    selection
                    options={speakers.map(([deviceId, details]) => { return { key: deviceId, value: deviceId, text: details.label } })}
                    value={activeSpeaker}
                    onChange={(_e, element) => handleActiveSpeakerChange(element.value)}
                    placeholder='Select Speaker'
                  />
                  <AudioMeter level={outputLevel} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Popup>

          {callData.call && (
            <>
              <Popup
                trigger={<Button icon="keyboard" style={{ margin: 5 }}/>}
                on="click"
                position="bottom center"
                className="keyboardPopup"
              >
                <Grid columns={3}>
                  {'123456789*0#'.split('').map((digit) => (
                    <Grid.Column key={digit}>
                      <Button basic onClick={() => sendDigit(digit)}>
                        {digit}
                      </Button>
                    </Grid.Column>
                  ))}
                </Grid>
              </Popup>
              <Button
                icon={`microphone${muted ? ' slash' : ''}`}
                onClick={() => setMuted(!muted)}
                active={muted}
                style={{ margin: 5 }}
              />
            </>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default withRouter(TwilioCall);
