import React from 'react';
import { Header, Card } from 'semantic-ui-react';
import ProspectCard from './ProspectCard';

const ProspectPicker = ({ scheduledPhoneCall, outcomes, handleProspectSelection }) => {

  return (
    <>
      <Header as='h3'>Pick from recommended prospects:</Header>
      <Card.Group itemsPerRow={1}>
        {scheduledPhoneCall.prospects.map((prospect, i) =>
          <ProspectCard
            prospect={prospect}
            key={i}
            handleProspectSelection={handleProspectSelection}
            phoneCallOutcomes={outcomes}
            scheduledPhoneCall={scheduledPhoneCall}
          />
        )}
        {scheduledPhoneCall.prospects.length === 0 && (
          <Header as='h4' style={{ marginTop: 10 }}>We can't match any prospect with this phone number.</Header>
        )}
      </Card.Group>
    </>
  )
}

export default ProspectPicker;
