import React, { useState } from "react";
import { Card, Grid, Icon, Form, Button, Divider } from "semantic-ui-react";
import { formInput } from "../../../shared/utils.js";
import axios from "axios";

const CreateProspect = ({ setDisplayProspectCreation, handleProspectSelection }) => {
  const [prospect, setProspect] = useState({ linkedInProfileUrl: "", name: "" })
  const [loading, setLoading] = useState(false)

  const onChange = (event) => {
    setProspect({ ...prospect, [event.target.name]: event.target.value })
  }

  const onSubmit = () => {
    setLoading(true)
    axios.post(`/cold_calling/incoming_create_prospect`, { ...prospect }).then(({ data }) => {
      setLoading(false)
      if (data.errors) {
        setProspect((prevProspect) => ({ ...prevProspect, errors: data.errors }))
      } else {
        handleProspectSelection({ id: data.id })
      }
    })
  }

  return (
    <>
      <Divider section horizontal>
        <div> <p style={{ textTransform: 'none', fontSize: 14 }}>Or create prospect:</p> </div>
      </Divider>
      <Form id="create-prospect">
        <Grid columns='equal'>
          <Grid.Column width={8}>
            {formInput(prospect, "linkedInProfileUrl", "Profile Url", onChange, Form.Input, { autoFocus: true })}
          </Grid.Column>
          <Grid.Column width={8}>
            {formInput(prospect, "name", "Name", onChange, Form.Input)}
          </Grid.Column>
          <Grid.Column width={16}>
            <Button
              type="submit"
              onClick={onSubmit}
              loading={loading}
              style={{ width: '100%' }}
            >
              Create
            </Button>
          </Grid.Column>
        </Grid>
      </Form>
    </>
  )
}

export default CreateProspect;
