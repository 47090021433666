import React from 'react'
import { Card } from 'semantic-ui-react'
import moment from 'moment'

const ProspectCard = ({ prospect, handleProspectSelection, phoneCallOutcomes, disablePickup, scheduledPhoneCall }) => {
  let latestScheduledPhoneCall = prospect.latestScheduledPhoneCall
  let scheduledPhoneDate
  let outcomeText

  let getScpDate = (scp) => (
    moment(scp.completedAt || scp.scheduledFor || scp.createdAt)
  )
  let getOutcomeText = (scp) => (
    scp.phoneCallOutcomeId && phoneCallOutcomes.find(outcome => outcome.id === scp.phoneCallOutcomeId)?.name
  )
  if (latestScheduledPhoneCall) {
    scheduledPhoneDate = getScpDate(latestScheduledPhoneCall)
    outcomeText = getOutcomeText(latestScheduledPhoneCall)
  }

  let notesFormatted = prospect.scheduledPhoneCallNotes.map(scp => {
    let spd = getScpDate(scp)
    let ot = getOutcomeText(scp)

    return (
      <p key={scp.id}>
        <b>{spd.format('DD.MM.YYYY')}{ot && ` - ${ot}`}:</b> {scp.notes}
      </p>
    )
  })

  return (
    <Card
      onClick={() => !disablePickup && handleProspectSelection(prospect)}
      style={{ cursor: disablePickup ? 'default' : 'pointer' }}
    >
      <Card.Content>
        <Card.Header>{prospect.name}</Card.Header>
        <Card.Meta>
          {prospect.primaryCompanyName && (
            <p>{prospect.primaryCompanyName}{prospect.title && `, ${prospect.title}`}</p>
          )}
        </Card.Meta>

        <Card.Description>
          {latestScheduledPhoneCall && (
            <>
              <p>Campaign: <b>{latestScheduledPhoneCall.campaignGroup.name}</b></p>
              <p>
                {scheduledPhoneDate.isAfter(moment()) ? 'Scheduled for' : 'Last called at'} {scheduledPhoneDate.format('DD.MM.YYYY')}
                {outcomeText && ` - ${outcomeText}`}
              </p>
            </>
          )}
          {prospect.notes && (
            <p><b>Prospect Notes:</b> {prospect.notes}</p>
          )}
          {prospect.scheduledPhoneCallNotes.length > 0 && (
            <>
              <p><b>Notes from previous calls:</b></p>
              {notesFormatted}
            </>
          )}

          {prospect.dataProviderInformation.length > 0 && (
            <>
              <p><b>Phone Numbers:</b></p>
              {prospect.dataProviderInformation.map(dpi => (
                <p key={dpi.id}>
                  {scheduledPhoneCall && scheduledPhoneCall.sourcePhoneCall.callingPhone.includes(dpi.userSanitizedValue || dpi.value) ? (
                    <b>{dpi.userSanitizedValue || dpi.value}</b>
                  ) : (
                    dpi.userSanitizedValue || dpi.value
                  )}
                </p>
              ))}
            </>
          )}
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default ProspectCard
