import React, { useState, useRef, useEffect } from "react";
import { Dropdown, Grid, Icon, Segment, List, Form, Divider } from 'semantic-ui-react';
import { present } from "../../shared/utils.js";
import moment from 'moment';
import axios from 'axios';

const DATE_FORMAT_SHORT = "DD.MM.Y H:mm";

const PhoneCalls = (props) => {
  const [displayPrevious, setDisplayPrevious] = useState(false);
  const [displayNext, setDisplayNext] = useState(false);
  const [currentCallDropdownFocused, setCurrentCallDropdownFocused] = useState(false);

  useEffect(() => {
    if (!props.callInProgress && currentCallConnectedInputRef.current && !currentCallDropdownFocused) {
      currentCallConnectedInputRef.current.focus()
      setCurrentCallDropdownFocused(true)
    }
  }, [props.callInProgress])

  useEffect(() => {
    if (currentCallDropdownFocused) {
      setCurrentCallDropdownFocused(false)
    }
  }, [props.callInProgress])

  let {
    currentScheduledPhoneCall, callInProgress, phoneCalls,
    currentCall, displayErrors, scheduledPhoneCalls, outcomes,
    campaignGroupId,
  } = props;

  scheduledPhoneCalls ||= []

  const currentCallConnectedInputRef = useRef(null);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const updateCall = (attribute, value, callId) => {
    let phoneCall = phoneCalls.find(call => call.id == callId)
    phoneCall[attribute] = value;
    props.setScheduledPhoneCallPhoneCalls([...phoneCalls])
  }

  const pollForRecordings = (call) => {
    let phoneCall = phoneCalls.find(c => c.id == call.id)

    axios.get(`/phone_calls/recordings/${call.id}/`).then(({ data }) => {
      if (data.length > 0) {
        phoneCall['recordingUrls'] = data;
        props.setScheduledPhoneCallPhoneCalls([...phoneCalls])
      } else {
        setTimeout(() => pollForRecordings(call), 3000)
      }
    }).catch(error => {
      console.error('error while polling for recordings', error)
    })
  }

  useEffect(() => {
    phoneCalls.forEach(call => {
      if (call.connected === 'yes' && (!call.recordingUrls || call.recordingUrls.length === 0)) {
        pollForRecordings(call);
      }
    });
  }, [phoneCalls]);

  const renderPhoneCall = (call, i, currentCallId) => {
    let disableInputs = currentScheduledPhoneCall.id != call.scheduledPhoneCallId
    let callDate = moment(call.createdAt)
    let phoneNumber = call.incoming ? call.callingPhone : call.calledPhone

    return (
      <Segment key={i} id={`phone-call-${call.id}`}>
        <Grid verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={14}>
              {call?.user && (
                <List.Item>
                  <List.Header>
                    <Icon name='user' /> {call?.user?.name}
                  </List.Header>
                </List.Item>
              )}
            </Grid.Column>
            <Grid.Column width={1} floated='right' style={{ marginRight: 10 }}>
              {callInProgress && currentCallId == call.id && (
                <Icon name='volume control phone' color="green" title="Ongoing call" />
              )}
              {(!callInProgress || currentCallId != call.id) && call.connected == 'yes' && (
                <Icon name='phone' color="blue" title="Connected" />
              )}
              {(!callInProgress || currentCallId != call.id) && call.connected != 'yes' && (
                <Icon name='phone' color="red" title="Unconnected" />
              )}
            </Grid.Column>
            <Grid.Column width={16}>
              <List.Item>
                <List.Header>
                  {/* We could display the phone number from call.dataProviderInformation but it's value could be
                  changed after the call is made. `phone` is the value used by Twilio to make the call. */}
                  <>
                    <Icon name='phone' />{phoneNumber} {call.incoming ? '(incoming)' : undefined}
                  </>
                </List.Header>
              </List.Item>
            </Grid.Column>
            <Grid.Column width={16}>
              <List.Item>
                <List.Header>
                  <Icon name='clock' />
                  {moment(call.createdAt).format(DATE_FORMAT_SHORT)}&nbsp;
                </List.Header>
              </List.Item>
            </Grid.Column>
            <Grid.Column width={16}>
              <List.Item>
                <List.Header>
                  <Icon name='hourglass outline' />
                  {formatTime(call.duration)}
                </List.Header>
              </List.Item>
            </Grid.Column>
          </Grid.Row>

          {present(call.notes) && (
            <Grid.Row>
              <Grid.Column width={16} verticalAlign='middle'>
                <b style={{ marginRight: 5 }}>Notes:</b>
                {call.notes}
              </Grid.Column>
            </Grid.Row>
          )}

          {present(call.recordingUrls) && (
            call.recordingUrls.map((url, i) => {
              let recordingFilename = `${call.calledPhone.replace(/ /g, '')}_${call?.user?.name?.replace(/ /g, '-')}_${callDate.format('DD-MM_H-mm')}.wav`

              return (
                <Grid.Row key={i}>
                  <Grid.Column width={12}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <audio controls preload="metadata" style={{ flexGrow: 1 }}>
                        <source src={`/phone_calls/recording?id=${url}`} type="audio/wav" />
                        Your browser does not support the audio tag.
                      </audio>
                      <a href={`/phone_calls/recording?id=${url}`}
                        download={recordingFilename}
                        style={{ color: 'black', marginLeft: '10px' }}
                      >
                        <Icon name="download" size="large" />
                      </a>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              )
            })
          )}

          <Grid.Row>
            <Grid.Column width={16}>
              <Form.Field required>
                <label>Connected?</label>
                {currentCallId == call.id && (
                  <Dropdown
                    selection
                    fluid
                    options={[
                      { key: 'invalid-number', text: 'No - Number cannot be called', value: 'invalid-number' },
                      { key: 'nobody-picked-up', text: 'No - Nobody picked up', value: 'nobody-picked-up' },
                      { key: 'busy', text: 'No - Number was busy', value: 'busy' },
                      { key: 'yes', text: 'Yes', value: 'yes' },
                    ]}
                    onChange={(_e, element) => updateCall('connected', element.value, call.id)}
                    value={call.connected}
                    error={displayErrors && call.connected == null}
                    disabled={disableInputs}
                    ref={currentCallConnectedInputRef}
                    className="connected-dropdown"
                  />
                )}
                {currentCallId != call.id && (
                  <Dropdown
                    selection
                    fluid
                    options={[
                      { key: 'invalid-number', text: 'No - Number cannot be called', value: 'invalid-number' },
                      { key: 'nobody-picked-up', text: 'No - Nobody picked up', value: 'nobody-picked-up' },
                      { key: 'busy', text: 'No - Number was busy', value: 'busy' },
                      { key: 'yes', text: 'Yes', value: 'yes' },
                    ]}
                    onChange={(_e, element) => updateCall('connected', element.value, call.id)}
                    value={call.connected}
                    error={displayErrors && call.connected == null}
                    disabled={disableInputs}
                    className="connected-dropdown"
                  />
                )}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          {call.connected === 'yes' && (
            <>
              <Grid.Row style={{ paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                <Grid.Column width={8}>
                  <Form.Field required>
                    <label>Person ok?</label>
                    <Dropdown
                      placeholder='Person ok?'
                      selection
                      options={[
                        { key: 'yes', text: 'Yes', value: true },
                        { key: 'no', text: 'No', value: false },
                      ]}
                      onChange={(_e, element) => updateCall('personOk', element.value, call.id)}
                      value={call.personOk}
                      error={displayErrors && call.personOk == null}
                      disabled={disableInputs}
                      className="person-ok-dropdown"
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Field required>
                    <label>Company ok?</label>
                    <Dropdown
                      placeholder='Company ok?'
                      selection
                      options={[
                        { key: 'yes', text: 'Yes', value: true },
                        { key: 'no', text: 'No', value: false },
                      ]}
                      onChange={(_e, element) => updateCall('companyOk', element.value, call.id)}
                      value={call.companyOk}
                      error={displayErrors && call.companyOk == null}
                      disabled={disableInputs}
                      className="company-ok-dropdown"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingTop: '0.5em' }}>
                <Grid.Column width={8}>
                  <Form.Field required>
                    <label>Gatekeeper?</label>
                    <Dropdown
                      placeholder='Gatekeeper?'
                      selection
                      options={[
                        { key: 'yes', text: 'Yes', value: true },
                        { key: 'no', text: 'No', value: false },
                      ]}
                      onChange={(_e, element) => updateCall('gatekeeper', element.value, call.id)}
                      value={call.gatekeeper}
                      error={displayErrors && call.gatekeeper == null}
                      disabled={disableInputs}
                      className="gatekeeper-dropdown"
                    />
                  </Form.Field>
                </Grid.Column>
                {call.gatekeeper === true && (
                  <Grid.Column width={8}>
                    <Form.Field required>
                      <label>Got through?</label>
                      <Dropdown
                        placeholder='Got through?'
                        selection
                        options={[
                          { key: 'yes', text: 'Yes', value: 'yes' },
                          { key: 'yes-but-nobody-picked-up', text: 'Yes - But nobody picked up', value: 'yes-but-nobody-picked-up' },
                          { key: 'no', text: 'No', value: 'no' },
                        ]}
                        onChange={(_e, element) => updateCall('gotThrough', element.value, call.id)}
                        value={call.gotThrough}
                        error={displayErrors && call.gotThrough == null}
                        disabled={disableInputs}
                        className="got-through-dropdown"
                      />
                    </Form.Field>
                  </Grid.Column>
                )}
              </Grid.Row>
            </>
          )}
        </Grid>
      </Segment>
    )
  }

  const phoneCallsGroupedByScheduledPhoneCall = phoneCalls.reduce((result, currentItem) => {
    const key = currentItem.scheduledPhoneCallId;
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(currentItem);
    return result;
  }, {});

  const renderPhoneCallsForScheduledPhoneCall = (scheduledPhoneCall, active) => {
    if (!phoneCallsGroupedByScheduledPhoneCall[scheduledPhoneCall.id]) {
      if (active) {
        return (
          <div style={{ textAlign: 'center', marginTop: '10px', padding: '20px', color: '#555' }}>
            <Icon name="phone" size="large" style={{ marginRight: '10px', position: "relative", top: -2, fontSize: '1.3em' }} />
            <span style={{ fontSize: '1.00em', }}>No calls yet! Your calls will be displayed here.</span>
          </div>
        )
      } else {
        return (
          <div style={{ textAlign: 'center', marginTop: '10px', padding: '20px', color: '#555' }}>
            <span style={{ fontSize: '1.00em', }}>No phone calls made for this scheduled phone call.</span>
          </div>
        )
      }
    } else {
      return (
        <div key={scheduledPhoneCall.id}>
          {
            phoneCallsGroupedByScheduledPhoneCall[scheduledPhoneCall.id].map((phoneCall, i) =>
              renderPhoneCall(phoneCall, i, currentCall?.phoneCallId)
            )
          }
        </div>
      )
    }
  }

  const renderScheduledPhoneCall = (scheduledPhoneCall, i, isCurrent) => {
    if (!scheduledPhoneCall) return null;
    let scheduledPhoneDate = moment(scheduledPhoneCall.completedAt || scheduledPhoneCall.scheduledFor || scheduledPhoneCall.createdAt).format('DD.MM.YYYY')
    let outcomeText = scheduledPhoneCall.phoneCallOutcomeId && outcomes.find(outcome => outcome.id == scheduledPhoneCall.phoneCallOutcomeId)?.name
    outcomeText ||= '[none yet]'

    let text = `${scheduledPhoneDate}, ${outcomeText}`
    let ElementType = "a";
    if (isCurrent) {
      ElementType = "span";
      if (scheduledPhoneCall.phoneCallOutcomeId) {
        text = "Currently open: " + text
      } else {
        text = "Current"
      }
    }

    return (
      <div key={i}>
        <Divider section horizontal>
          <ElementType href={scheduledPhoneCall.createdViaIncomingCall ?
            `/cold_calling/incoming/${scheduledPhoneCall.id}` :
            `/cold_calling/${scheduledPhoneCall.campaignGroup.id}/${scheduledPhoneCall.id}`}
          >{text}</ElementType>
          <div> <i style={{ textTransform: 'none', fontSize: 12 }}>{scheduledPhoneCall.completedBy?.name.replace(/\(.*?\)/g, "")}</i> </div>
        </Divider>
        { renderPhoneCallsForScheduledPhoneCall(scheduledPhoneCall, isCurrent && !scheduledPhoneCall.phoneCallOutcomeId) }
      </div>
    )
  }

  let prevScheduledPhoneCalls = []
  let nextScheduledPhoneCalls = []
  let pushPrev = false
  for (var i = 0; i < scheduledPhoneCalls.length; i++) {
    if (scheduledPhoneCalls[i].id == currentScheduledPhoneCall?.id) {
      pushPrev = true;
    } else if (pushPrev) {
      prevScheduledPhoneCalls.push(scheduledPhoneCalls[i])
    } else {
      nextScheduledPhoneCalls.push(scheduledPhoneCalls[i])
    }
  }

  prevScheduledPhoneCalls = prevScheduledPhoneCalls.reverse()
  nextScheduledPhoneCalls = nextScheduledPhoneCalls.reverse()

  return (
    <List divided relaxed>
      {!displayPrevious && prevScheduledPhoneCalls.length > 0 && (
        <Divider section horizontal>
          <a onClick={() => setDisplayPrevious(!displayPrevious)} style={{color: '#999', fontSize: 10,}}>
            {displayPrevious ? 'Hide' : 'View'} Previous Calls ({prevScheduledPhoneCalls.length})
            <i className={!displayPrevious ? "caret up icon" : "caret down icon"}></i>
          </a>
        </Divider>
      )}
      {displayPrevious && (
        <React.Fragment>
          {prevScheduledPhoneCalls.map((scheduledPhoneCall, i) =>
            renderScheduledPhoneCall(scheduledPhoneCall, i)
          )}
        </React.Fragment>
      )}

     { renderScheduledPhoneCall(currentScheduledPhoneCall, "current", true) }

      {displayNext && (
        <React.Fragment>
          {nextScheduledPhoneCalls.map((scheduledPhoneCall, i) =>
            renderScheduledPhoneCall(scheduledPhoneCall, i)
          )}
        </React.Fragment>
      )}
      {!displayNext && nextScheduledPhoneCalls.length > 0 && (
        <Divider section horizontal>
          <a onClick={() => setDisplayNext(!displayNext)} style={{color: '#999', fontSize: 10,}}>
            {displayNext ? 'Hide' : 'View'} Next Calls ({nextScheduledPhoneCalls.length})
            <i className={displayNext ? "caret up icon" : "caret down icon"}></i>
          </a>
        </Divider>
      )}
    </List>
  )
}

export default PhoneCalls;
