import React, { useState, useEffect }  from 'react';
import DatePicker from "react-datepicker";
import { Grid, Dropdown, Form } from 'semantic-ui-react'

const MotionDatePicker = ({ onChange = () => {}, ...rest }) => {
  const [state, setState] = useState({ range: 'all', from: new Date(), to: new Date(), ...rest })

  let options = rest.options || [
    { key: 'all', value: 'all', text: 'all' },
    { key: 'this_day', value: 'this_day', text: 'Today' },
    { key: 'last_day', value: 'last_day', text: 'Yesterday' },
    { key: 'this_week', value: 'this_week', text: 'This week' },
    { key: 'last_week', value: 'last_week', text: 'Last week' },
    { key: 'this_month', value: 'this_month', text: 'This month' },
    { key: 'last_month', value: 'last_month', text: 'Last month' },
    { key: 'this_quarter', value: 'this_quarter', text: 'This quarter' },
    { key: 'last_quarter', value: 'last_quarter', text: 'Last quarter' },
    { key: 'this_year', value: 'this_year', text: 'This year' },
    { key: 'last_year', value: 'last_year', text: 'Last year' },
    { key: 'custom', value: 'custom', text: 'Custom' }
  ]

  useEffect(() => {
    if (rest.range == null) {
      onChange({ ...state, range: options[0].value });
    } else {
      setState((prevState) => ({ ...prevState, range: rest.range }));
    }
  }, [rest.range])

  const setStateWithCallback = (data) => {
    const newState = { ...state, ...data }
    setState(newState)
    onChange({ ...newState, ...((newState.range !== 'custom' || newState.range === '') && { from: null, to: null })})
  }


  const {
    range, from, to
  } = state;



  return (
      <Grid>
        <Grid.Column width={range === 'custom' ? (rest.showTimeSelect ? 6 : 8) : 16}>
          <Form.Field>
            <label>{rest.label}</label>
            <Dropdown
              placeholder='…'
              fluid
              selection
              options={options}
              labeled
              value={range}
              onChange={(_, data) => setStateWithCallback({ range: data.value })}
              id={rest.id}
              disabled={rest.disabled}
            />
          </Form.Field>
        </Grid.Column>
        {range === 'custom' && rest.rangeDatepicker && (
        <Grid.Column width={rest.showTimeSelect ? 10 : 8}>
            <Form.Field>
              <label>Date range</label>
              <DatePicker
                dateFormat={"dd.MM.yyyy"}
                onChange={date => { setStateWithCallback({ from: date[0], to: date[1] })}}
                startDate={(typeof from === "string" && from !== "") ? new Date(from) : from}
                endDate={(typeof to === "string" && to !== "") ? new Date(to) : to}
                selectsRange
                autoComplete="off"
                showTimeSelect={rest.showTimeSelect}
              />
            </Form.Field>
          </Grid.Column>
        )}
        {range === 'custom' && !rest.rangeDatepicker && (
          <Grid.Column width={rest.showTimeSelect ? 10 : 8}>
            <Form.Field>
              <label>Date</label>
              <DatePicker
                 dateFormat={rest.showTimeSelect ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy"}
                 onChange={date => { setStateWithCallback({ from: date })}}
                 selected={(typeof from === "string" && from !== "") ? new Date(from) : from}
                 autoComplete="off"
                 showTimeSelect={rest.showTimeSelect}
              />
            </Form.Field>
          </Grid.Column>
        )}
      </Grid>
  )
}

export default MotionDatePicker;
