import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import IncomingCalls from "./IncomingCalls";
import CallInterface from "./CallInterface";
import MakeCall from "./MakeCall";
import Index from "./Index";
import IncomingCallsIndex from "./IncomingCallsIndex";
import { initializeDevice } from "../../shared/Twilio/TwilioDevice";
import FlashMessage from "./FlashMessage";

const ColdCallingDashboard = (props) => {
  const handleIncomingCallsChange = (call, action = 'add') => {
    if (action === 'add') {
      setIncomingCalls((prevIncomingCalls) => [...prevIncomingCalls, call]);
    } else if (action === 'remove') {
      setIncomingCalls((prevIncomingCalls) => prevIncomingCalls.filter(c => c !== call));
    }
  }

  const [device, setDevice] = useState(null);
  const [incomingCalls, setIncomingCalls] = useState([]);
  const [callData, setCallData] = useState({})
  const pageChangeBlocker = useRef(null);

  useEffect(() => {
    initializeDevice(setDevice, handleIncomingCallsChange);
  }, []);

  useEffect(() => {
    // Twilio library has a bug that if user starts a call when there is an incoming call awaiting then
    // the incoming calls is being automatically rejected without sending callbacks
    // It results in a stalled incoming call being always displayed in the incoming calls list
    // as a workaround we are removing the incoming calls not present in the device.calls when the call is ended
    // Disabling this in test environment as it's the only easy way to test if incoming call is blocked without saving the call
    if (callData?.ended && process.env.NODE_ENV !== 'test') {
      setIncomingCalls(incomingCalls.filter(call => device?.calls.includes(call)));
    }
  }, [callData?.ended]);

  let data = { device, incomingCalls, callData }
  let handlers = { setDevice, setIncomingCalls, setCallData }

  return (
    <Router>
      <IncomingCalls
        device={device}
        phoneCallOutcomes={props.phoneCallOutcomes}
        data={data}
        handlers={handlers}
        pageChangeBlocker={pageChangeBlocker}
        incomingCalls={incomingCalls}
        callData={callData}
      />
      <FlashMessage />

      <Switch>
        <Route
          path="/calling/make_call"
          render={() =>
            <MakeCall
              phoneCallOutcomes={props.phoneCallOutcomes}
              device={device}
              callData={callData}
              setCallData={setCallData}
            />
          }
        />
        <Route
          path="/cold_calling/incoming/:scheduledPhoneCallId"
          render={(routeParams) =>
            <CallInterface
              callType='incoming'
              phoneCallOutcomes={props.phoneCallOutcomes}
              scheduledPhoneCallId={routeParams.match.params.scheduledPhoneCallId}
              scheduledPhoneCall={props.scheduledPhoneCall}
              crmStatuses={props.crmStatuses}
              users={props.users}
              currentUserId={props.currentUserId}
              campaigns={props.campaigns}
              tags={props.tags}
              data={data}
              handlers={handlers}
              pageChangeBlocker={pageChangeBlocker}
            />
          }
        />
        <Route
          path="/cold_calling/incoming"
          render={() =>
            <IncomingCallsIndex
              phoneCallOutcomes={props.phoneCallOutcomes}
              prospectDistributionRunning={props.prospectDistributionRunning}
            />
          }
        />
        <Route
          path="/cold_calling/:campaignGroupId/:scheduledPhoneCallId"
          render={(routeParams) =>
            <CallInterface
              callType='outgoing'
              blockProspectChange={true}
              phoneCallOutcomes={props.phoneCallOutcomes}
              campaignGroupId={routeParams.match.params.campaignGroupId}
              scheduledPhoneCallId={routeParams.match.params.scheduledPhoneCallId}
              scheduledPhoneCall={props.scheduledPhoneCall}
              crmStatuses={props.crmStatuses}
              users={props.users}
              currentUserId={props.currentUserId}
              campaigns={props.campaigns}
              tags={props.tags}
              data={data}
              handlers={handlers}
              pageChangeBlocker={pageChangeBlocker}
            />
          }
        />
        <Route
          path="/cold_calling/:campaignGroupId"
          render={(routeProps) =>
            <CallInterface
              callType='outgoing'
              blockProspectChange={true}
              campaignGroupId={routeProps.match.params.campaignGroupId}
              phoneCallOutcomes={props.phoneCallOutcomes}
              data={data}
              handlers={handlers}
              pageChangeBlocker={pageChangeBlocker}
            />
          }
        />
        <Route
          path="/cold_calling"
          render={() =>
            <Index
              campaignGroups={props.campaignGroups}
              prospectDistributionRunning={props.prospectDistributionRunning}
            />
          }
        />
      </Switch>
    </Router>
  )
}

export default ColdCallingDashboard;
