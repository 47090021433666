import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, TextArea, Grid, Segment, Message, Input, Dropdown } from 'semantic-ui-react';
import axios from 'axios';
import TwilioCall from '../../shared/Twilio/TwilioCall.js';
import Help from './Help.js';
import { Link } from '@material-ui/core';
import { present } from '../../shared/utils';

const MakeCall = ({ phoneCallOutcomes, device, callData, setCallData }) => {
  const [localCallData, setLocalCallData] = useState({ phoneNumber: '', phoneCallOutcomeId: null, notes: '', customCampaign: '' });
  const callInProgress = present(callData) && !callData.ended

  const pageChangeBlocker = useRef(null);

  useEffect(() => {
    return () => {
      if (pageChangeBlocker.current) {
        window.removeEventListener("beforeunload", pageChangeBlocker.current);
        pageChangeBlocker.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (callInProgress && !pageChangeBlocker.current) {
      const onBeforeUnload = (ev) => {
        if (ev) {
          ev.preventDefault();
          ev.returnValue = 'Are you sure you want to leave?';
        }

        return 'Are you sure you want to leave?';
      }

      window.addEventListener("beforeunload", onBeforeUnload);

      pageChangeBlocker.current = onBeforeUnload;
    }
  }, [callInProgress]);

  const handleSave = async () => {
    if (!callData.phoneCallId) return;

    axios.post(`/phone_calls/naked_phone_call`, { phoneCallId: callData.phoneCallId, ...localCallData }).then(() => {
      if (!callInProgress) {
        setLocalCallData({ phoneNumber: '', phoneCallOutcomeId: null, notes: '', customCampaign: '' })
        setCallData({})

        if (pageChangeBlocker.current) {
          window.removeEventListener("beforeunload", pageChangeBlocker.current);
          pageChangeBlocker.current = null;
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  };

  return (
    <>
      <Help />
      <Grid divided style={{ marginTop: 15 }} centered={true}>
        <Grid.Column width={12}>
          <div style={{ marginTop: 5, marginBottom: 5 }}><Link href="/cold_calling" >Back to Cold Calling</Link></div>
          <Form>
            <TwilioCall
              sessionScope='naked_phone_call'
              callData={callData}
              setCallData={setCallData}
              selectedDataProviderInformation={{ value: localCallData.phoneNumber }}
              blockCallButton={callData.ended}
              device={device}
            >
              <Input value={callData.phoneNumber} onChange={(e) => {
                const { value } = e.target;
                setLocalCallData((prevCallData) => ({ ...prevCallData, phoneNumber: value }))
              }} />
            </TwilioCall>

            <Segment>
              <Form.Field>
                <label>Call Outcome</label>
                <Dropdown
                  fluid
                  selection
                  options={phoneCallOutcomes.map(o => ({ key: o.id, value: o.id, text: o.name }))}
                  style={{ marginBottom: 10 }}
                  value={localCallData.phoneCallOutcomeId}
                  onChange={(_e, element) => setLocalCallData((prevCallData) => ({ ...prevCallData, phoneCallOutcomeId: element.value }))}
                />
              </Form.Field>
              <Form.Field>
                <Form.Field
                  control={TextArea}
                  label='Notes'
                  name='notes'
                  value={localCallData.notes || ''}
                  onChange={({ target }) => setLocalCallData((prevCallData) => ({ ...prevCallData, notes: target.value }))}
                />
              </Form.Field>

              <Form.Field>
                <Form.Field
                  control={Dropdown}
                  label='Custom Campaign'
                  placeholder='Select a campaign'
                  fluid
                  search
                  selection
                  options={
                    [
                      { key: '1', text: 'Instagram', value: 'Instagram' },
                      { key: '2', text: 'Facebook', value: 'Facebook' },
                      { key: '3', text: 'Google Ads', value: 'Google Ads' },
                      { key: '4', text: 'LinkedIn', value: 'LinkedIn' },
                    ]
                  }
                  value={localCallData.customCampaign}
                  onChange={(_e, element) => setLocalCallData((prevCallData) => ({ ...prevCallData, customCampaign: element.value }))}
                />
              </Form.Field>
            </Segment>

            <Form.Field
              control={Button}
              content={callInProgress ? 'Save' : 'Save & Start New Call'}
              onClick={handleSave}
              style={{ width: '100%', }}
            />
          </Form>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default MakeCall;
