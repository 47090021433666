import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, withRouter, } from "react-router-dom";
import { Table, Segment, Header, Loader, Grid } from "semantic-ui-react";
import FlashMessage from "../../components/FlashMessage/index.js";
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroller";
import { uniq } from "underscore";
import moment from "moment";

const IncomingCallsIndex = (props) => {
  const { phoneCallOutcomes, prospectDistributionRunning } = props;
  let [fetchedAllPages, setFetchedAllPages] = useState(false);
  let [loading, setLoading] = useState(true);
  const [scheduledPhoneCalls, setScheduledPhoneCalls] = useState([]);

  const DATE_FORMAT_SHORT = "DD.MM.Y H:mm";

  useEffect(() => {
    fetchScheduledPhoneCalls();
  }, []);

  const fetchScheduledPhoneCalls = (page = 1) => {
    axios
      .get(`/cold_calling/incoming.json?page=${page}`)
      .then(({ data }) => {
        setFetchedAllPages(page == data.totalPages);
        setScheduledPhoneCalls((prevScheduledPhoneCalls) => {
          const allScheduledPhoneCalls = uniq(
            prevScheduledPhoneCalls.concat(data.scheduledPhoneCalls),
            (e) => e.id
          );

          return allScheduledPhoneCalls;
        });
        setLoading(false);
      })
      .catch((error) => {
        if (loading) setLoading(false);
        console.error('Failed to fetch scheduled phone calls:', error);
      });
  };

  let flashMessage = props.location.state?.flash

  if (prospectDistributionRunning) {
    return (
      <div style={{ marginTop: 30 }}>
        {flashMessage && <FlashMessage
          error
          header='Error!'
          timeout={5000}
          removeOnTimeout
          content={flashMessage}
          onClose={() => props.history.replace()}
        />}
        <Grid>
          <Grid.Row>
            <Grid.Column width={4} floated='left' style={{ paddingLeft: 0 }}>
              <Header as='h2' >
                Incoming calls dashboard
              </Header>
            </Grid.Column>
            <Grid.Column width={4} floated='right'>
              <Header as='h4' style={{ marginTop: 8 }}>
                <Link to="/cold_calling">Cold calling</Link>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            An admin has recently updated the campaigns and searches of your company.
            The system is currently recalculating which prospects should be contacted.
            Please check back in a couple of minutes.
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  if (scheduledPhoneCalls.length === 0) {
    return (
      <div style={{ marginTop: 30 }}>
        {flashMessage && <FlashMessage
          error
          header='Error!'
          timeout={5000}
          removeOnTimeout
          content={flashMessage}
          onClose={() => props.history.replace()}
        />}
        <Grid>
          <Grid.Row>
            <Grid.Column width={4} floated='left' style={{ paddingLeft: 0 }}>
              <Header as='h2' >
                Incoming calls dashboard
              </Header>
            </Grid.Column>
            <Grid.Column width={4} floated='right'>
              <Header as='h4' style={{ marginTop: 8 }}>
                <Link to="/cold_calling">Cold calling</Link>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {loading ? <Loader active /> : <>There are no incoming phone calls.</>}
          </Grid.Row>
        </Grid>
      </div>
    )
  }

  return (
    <div style={{ marginTop: 30 }}>
      {flashMessage && <FlashMessage
        error
        header='Error!'
        timeout={5000}
        removeOnTimeout
        content={flashMessage}
        onClose={() => props.history.replace()}
      />}
      <Header as='h2' floated='left' style={{ paddingLeft: 0 }}>
        Incoming calls dashboard
      </Header>
      <Header as='h4' floated='right' style={{ marginTop: 8 }}>
        <Link to="/cold_calling">Cold calling</Link>
      </Header>
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={(page) => {
          setLoading(true);
          fetchScheduledPhoneCalls(page)
        }}
        hasMore={!loading && !fetchedAllPages}
      >
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Campaign</Table.HeaderCell>
              <Table.HeaderCell>Prospect</Table.HeaderCell>
              <Table.HeaderCell>Caller Phone</Table.HeaderCell>
              <Table.HeaderCell>Called Phone</Table.HeaderCell>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell>Completed At</Table.HeaderCell>
              <Table.HeaderCell>Outcome</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {scheduledPhoneCalls.map((scheduledPhoneCall) => (
              <Table.Row key={scheduledPhoneCall.id}>
                <Table.Cell>
                  {scheduledPhoneCall.phoneCallCampaignGroupAction ?
                    (scheduledPhoneCall.phoneCallCampaignGroupAction.campaignGroup.name) : "-"
                  }
                </Table.Cell>
                <Table.Cell>
                  {scheduledPhoneCall.prospect ? (
                    <Link to={`/prospects/${scheduledPhoneCall.prospect.id}`} target="_blank">{scheduledPhoneCall.prospect.name}</Link>
                  ) : "-"}
                </Table.Cell>
                <Table.Cell>
                  {scheduledPhoneCall.sourcePhoneCall?.callingPhone}
                </Table.Cell>
                <Table.Cell>
                  {scheduledPhoneCall.sourcePhoneCall?.calledPhone}
                </Table.Cell>
                <Table.Cell>
                  {scheduledPhoneCall.createdAt ? moment(scheduledPhoneCall.createdAt).format(DATE_FORMAT_SHORT) : "-"}
                </Table.Cell>
                <Table.Cell>
                  {scheduledPhoneCall.completedAt ? moment(scheduledPhoneCall.completedAt).format(DATE_FORMAT_SHORT) : "-"}
                </Table.Cell>
                <Table.Cell>
                  {scheduledPhoneCall.phoneCallOutcomeId ?
                    phoneCallOutcomes.find(outcome => outcome.id == scheduledPhoneCall.phoneCallOutcomeId)?.name :
                    "-"
                  }
                </Table.Cell>
                <Table.Cell>
                  <Link to={`/cold_calling/incoming/${scheduledPhoneCall.id}`}>Open</Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {loading && (
          <Segment>
            <Loader active style={{ marginTop: 100 }} />
          </Segment>
        )}
      </InfiniteScroll>
    </div>
  )
}

export default withRouter(IncomingCallsIndex);
