import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, withRouter, } from "react-router-dom";
import { Table, Segment, Header } from "semantic-ui-react";
import axios from 'axios';

const Index = (props) => {
  const [campaignGroups, setCampaignGroups] = useState(props.campaignGroups || []);
  const [prospectDistributionRunning, setProspectDistributionRunning] = useState(props.prospectDistributionRunning || false);

  useEffect(() => {
    const fetchCampaignGroups = async () => {
      try {
        const response = await axios.get('/cold_calling.json');
        setCampaignGroups(response.data.campaignGroups);
        setProspectDistributionRunning(response.data.prospectDistributionRunning);
      } catch (error) {
        console.error('Failed to fetch campaign groups:', error);
      }
    };

    fetchCampaignGroups();
  }, []);

  if (prospectDistributionRunning) {
    return (
      <div style={{ marginTop: 30 }}>
        <Header as='h2' floated='left'>
          Cold calling dashboard
        </Header>
        <Header as='h4' floated='right' style={{ marginTop: 8 }}>
          <Link to="/cold_calling/incoming">Incoming calls</Link>
        </Header>
        <Segment>
          An admin has recently updated the campaigns and searches of your company.
          The system is currently recalculating which prospects should be contacted.
          Please check back in a couple of minutes.
        </Segment>
        <Link to="/calling/make_call">Make a call without campaign</Link>
      </div>
    );
  }

  if (campaignGroups.reduce((s, g) => s+g.actionsCount, 0) === 0) {
    return (
      <div style={{ marginTop: 30 }}>
        <Header as='h2' floated='left'>
          Cold calling dashboard
        </Header>
        <Header as='h4' floated='right' style={{ marginTop: 8 }}>
          <Link to="/cold_calling/incoming">Incoming calls</Link>
        </Header>
        <Segment>
          There are no campaign groups with outstanding phone calls.
        </Segment>
        <Link to="/calling/make_call">Make a call without campaign</Link>
      </div>
    )
  }

  return (
    <div style={{ marginTop: 30 }}>
      <Header as='h2' floated='left'>
        Cold calling dashboard
      </Header>
      <Header as='h4' floated='right' style={{ marginTop: 8 }}>
        <Link to="/cold_calling/incoming">Incoming calls</Link>
      </Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell># scheduled phone calls</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {campaignGroups.map((campaignGroup) => (
            <Table.Row key={campaignGroup.id}>
              <Table.Cell>
                <Link to={`/cold_calling/${campaignGroup.id}`}>{campaignGroup.name}</Link>
              </Table.Cell>
              <Table.Cell>
                {campaignGroup.actionsCount || 0}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Link to="/calling/make_call">Make a call without campaign</Link>
    </div>
  )
}

export default withRouter(Index);
