import React, { useState, useCallback } from 'react';
import { Input, Loader, CardGroup, Divider } from 'semantic-ui-react';
import axios from 'axios';
import { debounce } from "debounce";
import ProspectCard from './ProspectCard';

const SearchProspects = ({ scheduledPhoneCall, outcomes, handleProspectSelection, suggestedProspects = [] }) => {
  let [searchQuery, setSearchQuery] = useState('')
  let [prospects, setProspects] = useState([])
  let [loading, setLoading] = useState(false)

  const suggestedProspectsIds = suggestedProspects.map(p => p.id)

  const debouncedSearchQueryUpdate = useCallback(
    debounce((searchQuery) => {
      if (searchQuery.length < 3) {
        setProspects([])
        return
      };

      setLoading(true)
      setProspects([])
      axios.post(`/cold_calling/incoming_search`, { search: searchQuery }).then(response => {
        console.log('response', response.data)
        setProspects(response.data.prospects.filter(p => !suggestedProspectsIds.includes(p.id)))
        setLoading(false)
      }).catch(error => {
        console.error('error', error)
        setLoading(false)
      })
    }, 300),
    []
  );

  const updateSearchQuery = (searchQuery) => {
    debouncedSearchQueryUpdate(searchQuery)

    setSearchQuery(searchQuery)
  }

  return (
    <>
      <Divider section horizontal>
        <div> <p style={{ textTransform: 'none', fontSize: 14 }}>Or search for prospects:</p> </div>
      </Divider>
      <Input
        placeholder='Search for prospects...'
        value={searchQuery}
        onChange={(e, { value }) => updateSearchQuery(value)}
        style={{ width: '100%'}}
      />
      {loading && <div style={{ margin: 20, textAlign: 'center' }}><Loader active inline /></div>}
      <CardGroup itemsPerRow={1}>
        {prospects.map((prospect, i) =>
          <ProspectCard
            prospect={prospect}
            key={i}
            onChange={() => console.log()}
            phoneCallOutcomes={outcomes}
            handleProspectSelection={handleProspectSelection}
            scheduledPhoneCall={scheduledPhoneCall}
          />
        )}
      </CardGroup>
    </>
  )
}

export default SearchProspects;
